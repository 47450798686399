<template>
    <Dialog
        :value="value"
        @input="$emit('input', false)"
        @close="$emit('input', false)"
        @submit="submit()"
        @cancel="$emit('input', false)"
        :title="$t(`users.dialog.${userUpdate || editOwnUser ? 'editTitle' : 'title'}`)"
        :submit-text="$t('users.dialog.submit')"
        :cancel-text="$t('users.dialog.cancel')"
        width="60rem"
    >
        <v-overlay :value="!userReady">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>

        <QuestionDialog
            v-if="userAddConfirmation"
            v-model="userAddConfirmation"
            :header="$t('users.emailDomainDiffQuestion')"
            :questionValue="true"
            @submit="(value) => createUser(value)"
            @cancel="userAddConfirmation = false"
        />

        <div v-if="userReady">
            <div>
                <div class="wrapper">
                    <div>
                        <label class="mandatory">{{ $t('users.dialog.givenName') }}</label>
                        <v-text-field
                            v-model="localValue.givenName"
                            dense
                            @input="givenNameMissing = false"
                            :class="{invalid: givenNameMissing}"
                            hide-details
                            outlined
                        ></v-text-field>
                    </div>

                    <div>
                        <label class="mandatory">{{ $t('users.dialog.surname') }}</label>
                        <v-text-field
                            v-model="localValue.surname"
                            @input="surnameMissing = false"
                            :class="{invalid: surnameMissing}"
                            dense
                            hide-details
                            outlined
                        ></v-text-field>
                    </div>

                    <div>
                        <label class="mandatory">{{ $t('users.dialog.email') }}</label>
                        <v-text-field
                            v-model="localValue.email"
                            @input="emailMissing = false"
                            :class="{invalid: emailMissing}"
                            dense
                            hide-details
                            outlined
                        ></v-text-field>
                    </div>

                    <div v-if="!editOwnUser || isLocateRiskAdmin">
                        <label class="mandatory">{{ $t('users.dialog.company') }}</label>
                        <v-autocomplete
                            :items="organizationsWithGrants"
                            @input="companyIdMissing = false"
                            :class="{invalid: companyIdMissing}"
                            item-text="name"
                            item-value="id"
                            v-model="localValue.CompanyId"
                            :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1"
                            :disabled="disableUserCompanySelection"
                            autocomplete="new-password"
                            dense
                            hide-details
                            outlined
                        ></v-autocomplete>
                    </div>
                    <div
                        v-if="
                            ((isDistributor && !isMyCompany) || isOrganizationalAdmin) &&
                            selectedOrganization?.isPartner &&
                            selectedOrganization?.availableGroups.length > 0 &&
                            canModifyGrants &&
                            !editOwnUser
                        "
                    >
                        <label>{{ $t('users.dialog.groups') }}</label>
                        <v-chip-group v-model="selectedGroups" column multiple>
                            <v-chip v-for="group in selectedOrganization.availableGroups" :key="group" filter outlined>
                                {{ group }}
                            </v-chip>
                        </v-chip-group>
                    </div>

                    <div>
                        <v-checkbox v-model="localValue.newsletter" hide-details outlined>
                            <template v-slot:label>
                                <label>{{ $t('users.dialog.newsletter') }}</label>
                            </template>
                        </v-checkbox>
                    </div>

                    <div v-if="userToUpdate">
                        <v-checkbox v-model="localValue.releaseNotes" hide-details outlined>
                            <template v-slot:label>
                                <label>{{ $t('users.dialog.releaseNotes') }}</label>
                            </template>
                        </v-checkbox>
                    </div>

                    <div v-if="canModifyGrants && !editOwnUser">
                        <label>{{ $t('users.dialog.grants') }}</label>
                        <v-card outlined style="padding: 1rem 1rem 0 1rem">
                            <v-checkbox
                                v-if="isOrganizationalAdmin || isDistributor || (selectedOrganization && selectedOrganization.CompanyId !== userData.companyId)"
                                v-model="organizationalAdmin"
                                @change="checkOAdmin($event)"
                            >
                                <template v-slot:label
                                    >{{ $t('users.dialog.organizationalAdmin') }}
                                    <v-tooltip top>
                                        <template v-slot:activator="{on, attrs}">
                                            <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                        </template>
                                        {{ $t('users.dialog.help_tooltips.organizationalAdmin') }}
                                    </v-tooltip>
                                </template>
                            </v-checkbox>

                            <div>
                                <strong>{{ $t('users.dialog.role_in_own_company') }}</strong>

                                <v-autocomplete
                                    :items="endCustomerRoles"
                                    item-text="name"
                                    item-value="id"
                                    v-model="endCustomerRole"
                                    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1"
                                    dense
                                    hide-details
                                    outlined
                                    :disabled="organizationalAdmin"
                                ></v-autocomplete>

                                <a class="blue--text text--darken-4 text-caption" @click="showSpecificEndCustomerGrants = !showSpecificEndCustomerGrants"
                                    ><template v-if="showSpecificEndCustomerGrants">{{ $t('users.dialog.hideSpecificGrants') }}</template
                                    ><template v-else>{{ $t('users.dialog.showSpecificGrants') }}</template></a
                                >
                                <template v-if="showSpecificEndCustomerGrants">
                                    <v-checkbox
                                        class="mt-2"
                                        v-model="allTaskGrantsOwnCompany"
                                        @click="checkUseFilterPossible"
                                        @change="checkTaskGrantsOwnCompany($event)"
                                        :disabled="organizationalAdmin"
                                    >
                                        <template v-slot:label
                                            >{{ $t('users.dialog.allTaskGrantsOwnCompany') }}
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                </template>
                                                {{ $t('users.dialog.help_tooltips.allTaskGrantsOwnCompany') }}
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>

                                    <v-checkbox
                                        v-model="viewAllTasksOwnCompany"
                                        @click="checkUseFilterPossible"
                                        :disabled="organizationalAdmin || allTaskGrantsOwnCompany"
                                    >
                                        <template v-slot:label
                                            >{{ $t('users.dialog.allViewTaskGrantsOwnCompany') }}
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                </template>
                                                {{ $t('users.dialog.help_tooltips.allViewTaskGrantsOwnCompany') }}
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>

                                    <v-checkbox v-model="useFilter" @click="onUseFilterCheck" :disabled="organizationalAdmin">
                                        <template v-slot:label
                                            >Filter verwalten
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                </template>
                                                {{ $t('users.dialog.help_tooltips.allFilterGrantsOwnCompany') }}
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>

                                    <v-checkbox v-model="allCompanyGrantsOwnCompany" :disabled="organizationalAdmin">
                                        <template v-slot:label
                                            >{{ $t('users.dialog.allCompanyGrantsOwnCompany') }}
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                </template>
                                                {{ $t('users.dialog.help_tooltips.allCompanyGrantsOwnCompany') }}
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox v-model="allUserGrantsOwnCompany" :disabled="organizationalAdmin">
                                        <template v-slot:label
                                            >{{ $t('users.dialog.allUserGrantsOwnCompany') }}
                                            <v-tooltip top>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                </template>
                                                {{ $t('users.dialog.help_tooltips.allUserGrantsOwnCompany') }}
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>
                                </template>
                                <v-divider style="margin-bottom: 0.5rem"></v-divider>
                                <div
                                    v-if="
                                        selectedOrganization &&
                                        (isOrganizationalAdmin || isLocateRiskAdmin || hasAllUserGrantsOwnCompany || isDistributor) &&
                                        selectedOrganization.isPartner
                                    "
                                >
                                    <strong
                                        >{{ $t('users.dialog.role_as_a_partner') }}
                                        <v-tooltip top>
                                            <template v-slot:activator="{on, attrs}">
                                                <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                            </template>
                                            {{ $t('users.dialog.help_tooltips.responsibleCompany') }}
                                        </v-tooltip></strong
                                    >

                                    <v-autocomplete
                                        :items="partnerRoles"
                                        item-text="name"
                                        item-value="id"
                                        v-model="partnerRole"
                                        :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1"
                                        dense
                                        hide-details
                                        outlined
                                        :disabled="organizationalAdmin"
                                    ></v-autocomplete>

                                    <a class="blue--text text--darken-4 text-caption" @click="showSpecificPartnerGrants = !showSpecificPartnerGrants"
                                        ><template v-if="showSpecificPartnerGrants">{{ $t('users.dialog.hideSpecificGrants') }}</template
                                        ><template v-else>{{ $t('users.dialog.showSpecificGrants') }}</template></a
                                    >

                                    <template v-if="showSpecificPartnerGrants">
                                        <div class="mt-2">
                                            <v-checkbox
                                                style="padding-top: 0.5rem"
                                                v-model="allTaskGrantsResponsibleCompanies"
                                                @change="checkTaskGrantsResponsibleCompanies($event)"
                                                :disabled="organizationalAdmin"
                                            >
                                                <template v-slot:label
                                                    >{{ $t('users.dialog.allTaskGrantsResponsibleCompanies') }}
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                        </template>
                                                        {{ $t('users.dialog.help_tooltips.allTaskGrantsResponsibleCompanies') }}
                                                    </v-tooltip>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox
                                                v-model="viewAllTasksResponsibleCompanies"
                                                :disabled="organizationalAdmin || allTaskGrantsResponsibleCompanies"
                                            >
                                                <template v-slot:label
                                                    >{{ $t('users.dialog.allViewTaskGrantsResponsibleCompanies') }}
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                        </template>
                                                        {{ $t('users.dialog.help_tooltips.allViewTaskGrantsResponsibleCompanies') }}
                                                    </v-tooltip>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox v-model="allCompanyGrantsResponsibleCompanies" :disabled="organizationalAdmin">
                                                <template v-slot:label
                                                    >{{ $t('users.dialog.allCompanyGrantsResponsibleCompanies') }}
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                        </template>
                                                        {{ $t('users.dialog.help_tooltips.allCompanyGrantsResponsibleCompanies') }}
                                                    </v-tooltip>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox v-model="allUserGrantsResponsibleCompanies" :disabled="organizationalAdmin">
                                                <template v-slot:label
                                                    >{{ $t('users.dialog.allUserGrantsResponsibleCompanies') }}
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                        </template>
                                                        {{ $t('users.dialog.help_tooltips.allUserGrantsResponsibleCompanies') }}
                                                    </v-tooltip>
                                                </template>
                                            </v-checkbox>
                                            <template
                                                v-if="
                                                    selectedOrganization &&
                                                    (isOrganizationalAdmin || isLocateRiskAdmin || isDistributor) &&
                                                    selectedOrganization.isPartner
                                                "
                                            >
                                                <v-divider style="margin-bottom: 0.5rem"></v-divider>
                                                <v-checkbox v-model="responsibleForAllCustomers" :disabled="organizationalAdmin">
                                                    <template v-slot:label
                                                        >{{ $t('users.dialog.responsibleForAllCustomers') }}
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{on, attrs}">
                                                                <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                            </template>
                                                            {{ $t('users.dialog.help_tooltips.responsibleForAllCustomers') }}
                                                        </v-tooltip>
                                                    </template>
                                                </v-checkbox>
                                                <v-checkbox
                                                    style="padding-top: 0.5rem"
                                                    v-model="allCompanyGrantsOnGroupCompanies"
                                                    :disabled="organizationalAdmin"
                                                >
                                                    <template v-slot:label
                                                        >{{ $t('users.dialog.allCompanyGrantsOnGroupCompanies') }}
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{on, attrs}">
                                                                <v-icon v-bind="attrs" v-on="on" class="ml-2">mdi-information-outline</v-icon>
                                                            </template>
                                                            {{ $t('users.dialog.help_tooltips.allCompanyGrantsOnGroupCompanies') }}
                                                        </v-tooltip>
                                                    </template>
                                                </v-checkbox>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </v-card>
                    </div>
                    <div style="margin-bottom: 1rem" v-if="(isLocateRiskAdmin || isOrganizationalAdmin) /*&& canModifyGrants*/ && !editOwnUser">
                        <v-card outlined>
                            <v-expansion-panels accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header v-slot="{}">
                                        <p>{{ $t('users.dialog.questionnaireGrants') }}</p>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content eager>
                                        <div>
                                            <v-checkbox
                                                v-model="viewQuestionnairesGrants"
                                                :label="$t('users.dialog.viewQuestionnairesGrants')"
                                                :disabled="organizationalAdmin"
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="modifyQuestionnairesGrants"
                                                :label="$t('users.dialog.modifyQuestionnairesGrants')"
                                                :disabled="organizationalAdmin"
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="inviteToQuestionnairesGrants"
                                                :label="$t('users.dialog.inviteToQuestionnairesGrants')"
                                                :disabled="organizationalAdmin"
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="viewOwnAnsweringsGrants"
                                                :label="$t('users.dialog.viewOwnAnsweringsGrants')"
                                                :disabled="organizationalAdmin"
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="viewSupplierAnsweringsGrants"
                                                :label="$t('users.dialog.viewSupplierAnsweringsGrants')"
                                                :disabled="organizationalAdmin"
                                            ></v-checkbox>
                                            <v-checkbox
                                                v-model="modifyAnsweringsGrants"
                                                :label="$t('users.dialog.modifyAnsweringsGrants')"
                                                :disabled="organizationalAdmin"
                                            ></v-checkbox>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </div>

                    <div v-if="isLocateRiskAdmin && !editOwnUser" style="margin-bottom: 1rem">
                        <v-card outlined>
                            <v-expansion-panels accordion>
                                <v-expansion-panel v-if="isLocateRiskAdmin && displayLRAdminElements" class="lr-admin">
                                    <v-expansion-panel-header v-slot="{}">
                                        <p>{{ $t('users.dialog.adminGrants') }}</p>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content eager>
                                        <div>
                                            <v-checkbox v-model="generalPrizes" :label="$t('users.dialog.generalPrizes')"></v-checkbox>
                                            <v-checkbox v-model="unvalidatedTasks" :label="$t('users.dialog.unvalidatedTasks')"></v-checkbox>
                                            <v-checkbox v-model="unvalidatedMGMT" :label="$t('users.dialog.unvalidatedMGMT')"></v-checkbox>
                                            <v-checkbox v-model="viewTrial" :label="$t('users.dialog.viewTrial')"></v-checkbox>
                                            <v-checkbox v-model="riskLists" :label="$t('users.dialog.riskLists')"></v-checkbox>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </div>
                </div>
            </div>

            <div v-if="isLocateRiskAdmin && displayLRAdminElements">
                <v-checkbox v-model="localValue.active" hide-details outlined>
                    <template v-slot:label>
                        <label class="lr-admin">{{ $t('users.dialog.active') }}</label>
                    </template>
                </v-checkbox>
            </div>

            <div v-if="isLocateRiskAdmin || editOwnUser">
                <v-checkbox v-if="userToUpdate && isLocateRiskAdmin" v-model="changePassword" hide-details outlined>
                    <template v-slot:label>
                        <label>{{ $t('users.dialog.changePassword') }}</label>
                    </template>
                </v-checkbox>
                <div>
                    <div v-if="changePassword && editOwnUser" style="margin-top: 1rem">
                        <!--  && !ownGrants[grants.LOCATE_RISK_ADMIN] -->
                        <label>{{ $t(`users.dialog.currentPassword`) }}</label>
                        <v-text-field
                            :append-icon="showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showCurrentPassword = !showCurrentPassword"
                            :type="showCurrentPassword ? 'text' : 'password'"
                            v-model="localValue.currentPassword"
                            dense
                            hide-details
                            autocomplete="new-password"
                            outlined
                        ></v-text-field>
                    </div>
                    <div v-if="changePassword || !userToUpdate">
                        <label>{{ $t(`users.dialog.${userToUpdate ? 'newPassword' : 'newPassword'}`) }}</label>
                        <v-text-field
                            :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showNewPassword = !showNewPassword"
                            :type="showNewPassword ? 'text' : 'password'"
                            v-model="localValue.password"
                            dense
                            hide-details
                            autocomplete="new-password"
                            outlined
                            :class="{invalid: passwordsDoesNotMatch}"
                        ></v-text-field>

                        <label>{{ $t(`users.dialog.newPasswordRepeat`) }}</label>
                        <v-text-field
                            :append-icon="showRepeatedPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showRepeatedPassword = !showRepeatedPassword"
                            :type="showRepeatedPassword ? 'text' : 'password'"
                            v-model="newPasswordRepeat"
                            dense
                            hide-details
                            autocomplete="new-password"
                            outlined
                            :class="{invalid: passwordsDoesNotMatch}"
                        ></v-text-field>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import Dialog from '../../components/dialogs/Dialog'
import Grants from '../../../../models/models/enums/GRANTS'
import Actions from '../../../../models/models/enums/ACTIONS'
import {createUserFrame, createLocateRiskAdminFrame, createOrganizationalAdminFrame} from '@/views/users/CreateGrantObject'
import QuestionDialog from '@/components/dialogs/QuestionDialog'

export default {
    name: 'UserDialog',
    components: {Dialog, QuestionDialog},
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        userUpdate: {
            type: Object,
            required: false,
            default: null
        },
        editOwnUser: {
            type: Boolean,
            required: false,
            default: false
        },
        addUserToCompanyId: {
            type: String,
            required: false
        },
        noUserGrants: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            userReady: false,
            localValue: {
                active: true,
                newsletter: false,
                releaseNotes: true,
                givenName: '',
                surname: '',
                email: '',
                CompanyId: '',
                password: '',
                currentPassword: ''
            },
            changePassword: false,
            showCurrentPassword: false,
            showRepeatedPassword: false,
            showNewPassword: false,
            givenNameMissing: false,
            surnameMissing: false,
            emailMissing: false,
            companyIdMissing: false,
            passwordsDoesNotMatch: false,

            locateRiskAdmin: false,
            organizationalAdmin: false,
            generalPrizes: false,
            unvalidatedTasks: false,
            unvalidatedMGMT: false,
            viewTrial: false,
            riskLists: false,
            allTaskGrantsOwnCompany: true,
            allUserGrantsOwnCompany: false,
            useFilter: false,
            allCompanyGrantsOwnCompany: false,
            viewAllTasksOwnCompany: true,
            allTaskGrantsResponsibleCompanies: false,
            allCompanyGrantsOnGroupCompanies: false,
            allUserGrantsResponsibleCompanies: false,
            allCompanyGrantsResponsibleCompanies: false,
            viewAllTasksResponsibleCompanies: false,
            responsibleForAllCustomers: false,
            viewQuestionnairesGrants: false,
            modifyQuestionnairesGrants: false,
            inviteToQuestionnairesGrants: false,
            viewOwnAnsweringsGrants: false,
            viewSupplierAnsweringsGrants: false,
            modifyAnsweringsGrants: false,

            newPasswordRepeat: '',
            userToUpdate: null,
            userGrants: null,

            grants: Grants.grants,
            actions: Actions.actions,
            selectedGroups: [],
            organizationsWithGrants: [],
            userAddConfirmation: false,
            showSpecificEndCustomerGrants: false,
            showSpecificPartnerGrants: false,
            endCustomerRoleVar: ''
        }
    },
    async created() {
        if (this.noUserGrants) {
            this.viewAllTasksOwnCompany = false
            this.allTaskGrantsOwnCompany = false
        }
        await this.loadLimitedOrganizationsInfo({includeLastLogin: false})
        this.resetLocalValue()
        this.localValue.CompanyId = this.addUserToCompanyId

        this.organizationsWithGrants = this.organizations.filter((org) => this.hasGrantsOnCompany(org.id))
        this.organizationsWithGrants.sort((a, b) => {
            return a.isPartner ? -1 : b.isPartner ? 1 : 0
        })

        /**
         this.currentUser.isPartner
         active: (...)
         companyId: "37ae20f3-318e-4ee9-979a-ea00679d062a"
         email: "lb@locaterisk.com"
         givenName: "Lukas"
         grants: Object
         hasPartner: false
         id: "521d5b73-56ad-4960-9074-a36984bld8f2"
         isPartner: true
         language: "de"
         name: "Lukas Baumann"
         surname: "Baumann"
         */
        if (this.userUpdate) {
            this.userToUpdate = this.userUpdate
        } else if (this.editOwnUser) {
            this.userToUpdate = this.currentUser
            // ToDo: companyId einheitlich machen...
            this.userToUpdate.CompanyId = this.currentUser.companyId
        } else {
            // Select organization if organizations has only one element
            if (this.organizationsWithGrants && this.organizationsWithGrants.length === 1) {
                this.localValue.CompanyId = this.organizationsWithGrants[0].id
            }
        }

        if (this.currentUser.grants[this.grants.ALL_USER_GRANTS_ON_OWN_COMPANY] && this.disableUserCompanySelection) {
            this.localValue.CompanyId = this.currentUser.companyId
        }

        if (this.userToUpdate) {
            if (this.currentUser && this.currentUser.id === this.userToUpdate.id) {
                this.userGrants = this.currentUser.grants
            } else {
                await this.loadUserGrants(this.userToUpdate.id)
                this.userGrants = this.requestedGrants
            }
            if (this.userGrants) {
                this.locateRiskAdmin = this.userGrants[this.grants.LOCATE_RISK_ADMIN]
                this.organizationalAdmin = this.userGrants[this.grants.ORGANIZATIONAL_ADMIN]
                this.generalPrizes =
                    (this.userGrants.specialGrants ? this.userGrants.specialGrants.includes(this.actions.MODIFY_GENERAL_PRICES) : false) ||
                    this.userGrants[this.grants.LOCATE_RISK_ADMIN]
                this.unvalidatedTasks =
                    (this.userGrants.specialGrants ? this.userGrants.specialGrants.includes(this.actions.CREATE_UNVALIDATED_TASKS) : false) ||
                    this.userGrants[this.grants.LOCATE_RISK_ADMIN]

                this.unvalidatedMGMT =
                    (this.userGrants.specialGrants ? this.userGrants.specialGrants.includes(this.actions.CREATE_UNVALIDATED_MGMT) : false) ||
                    this.userGrants[this.grants.LOCATE_RISK_ADMIN]
                this.viewTrial =
                    (this.userGrants.specialGrants ? this.userGrants.specialGrants.includes(this.actions.VIEW_TRIAL) : false) ||
                    this.userGrants[this.grants.LOCATE_RISK_ADMIN]

                this.riskLists =
                    (this.userGrants.specialGrants ? this.userGrants.specialGrants.includes(this.actions.MODIFY_RISK_LISTS) : false) ||
                    this.userGrants[this.grants.LOCATE_RISK_ADMIN]

                this.responsibleForAllCustomers = this.userGrants[this.grants.RESPONSIBLE_FOR_ALL_CUSTOMERS_OF_COMPANY]
                this.allCompanyGrantsOnGroupCompanies = this.responsibleForAllCustomers ? false : this.userGrants[this.grants.RESPONSIBLE_FOR_GROUP_COMPANIES]

                this.allUserGrantsOwnCompany = this.userGrants[this.grants.ALL_USER_GRANTS_ON_OWN_COMPANY]

                this.allUserGrantsResponsibleCompanies = this.userGrants[this.grants.ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES]

                this.allCompanyGrantsOwnCompany = this.userGrants[this.grants.ALL_COMPANY_GRANTS_ON_OWN_COMPANY]
                this.allCompanyGrantsResponsibleCompanies = this.userGrants[this.grants.ALL_COMPANY_GRANTS_ON_RESPONSIBLE_COMPANIES]

                this.allTaskGrantsOwnCompany = this.userGrants[this.grants.ALL_TASK_GRANTS_ON_OWN_COMPANY]
                this.allTaskGrantsResponsibleCompanies = this.userGrants[this.grants.ALL_TASK_GRANTS_ON_RESPONSIBLE_COMPANIES]

                this.useFilter =
                    (this.userGrants.grantsOwnCompany ? this.userGrants.grantsOwnCompany.includes(this.actions.USE_FILTER) : false) ||
                    this.userGrants[this.grants.LOCATE_RISK_ADMIN]

                this.viewAllTasksOwnCompany = this.userGrants[this.grants.CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY]
                this.viewAllTasksResponsibleCompanies = this.userGrants[this.grants.CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES]

                const hasQuestionnaireGrant = (action) => {
                    return (
                        this.userGrants[this.grants.LOCATE_RISK_ADMIN] ||
                        this.userGrants[this.grants.ORGANIZATIONAL_ADMIN] ||
                        Boolean(this.userGrants.questionnaireGrants?.includes(action))
                    )
                }
                this.viewQuestionnairesGrants = hasQuestionnaireGrant(this.actions.VIEW_QUESTIONNAIRE)
                this.modifyQuestionnairesGrants = hasQuestionnaireGrant(this.actions.MODIFY_QUESTIONNAIRE)
                this.inviteToQuestionnairesGrants = hasQuestionnaireGrant(this.actions.INVITE_TO_QUESTIONNAIRE)
                this.viewOwnAnsweringsGrants = hasQuestionnaireGrant(this.actions.VIEW_OWN_ANSWERING)
                this.viewSupplierAnsweringsGrants = hasQuestionnaireGrant(this.actions.VIEW_SUPPLIER_ANSWERING)
                this.modifyAnsweringsGrants = hasQuestionnaireGrant(this.actions.MODIFY_ANSWERING)
            }
        }
        this.userReady = true
    },
    computed: {
        ...mapState({
            organizations: (state) => state.organizationsLimited,
            userData: (state) => state.userData,
            requestedGrants: (state) => state.userGrants
        }),
        ...mapGetters([
            'isEmail',
            'isLocateRiskAdmin',
            'isOrganizationalAdmin',
            'hasAllUserGrants',
            'hasAllTaskGrants',
            'canViewAllTasks',
            'currentUser',
            'hasAllUserGrantsOwnCompany',
            'isResponsibleForAllCustomers',
            'hasAllUserGrantsResponsibleCompanies',
            'currentUserOrganization',
            'displayLRAdminElements'
        ]),

        endCustomerRole: {
            set(data) {
                if (this.$ENUMS.EndCustomerRoles.SECURITY_MANAGER === data) {
                    this.uncheckAllEndCustomerGrants()
                    this.allTaskGrantsOwnCompany = true
                    this.viewAllTasksOwnCompany = true
                    this.useFilter = true
                } else if (this.$ENUMS.EndCustomerRoles.ANALYST === data) {
                    this.uncheckAllEndCustomerGrants()
                    this.viewAllTasksOwnCompany = true
                    this.useFilter = true
                    // todo: add filter edit grants
                    // edit task immer mit filter grants
                } else if (this.$ENUMS.EndCustomerRoles.READ_ONLY === data) {
                    this.uncheckAllEndCustomerGrants()
                    this.viewAllTasksOwnCompany = true
                } else if (this.$ENUMS.EndCustomerRoles.NONE === data) {
                    this.uncheckAllEndCustomerGrants()
                }
            },
            get() {
                if (this.organizationalAdmin || (this.allTaskGrantsOwnCompany && this.viewAllTasksOwnCompany && this.useFilter)) {
                    return this.$ENUMS.EndCustomerRoles.SECURITY_MANAGER
                    // todo: add filter grant to the none check below
                } else if (!this.allCompanyGrantsOwnCompany && !this.allUserGrantsOwnCompany && !this.allTaskGrantsOwnCompany && !this.viewAllTasksOwnCompany) {
                    return this.$ENUMS.EndCustomerRoles.NONE
                } else if (this.allCompanyGrantsOwnCompany || this.allUserGrantsOwnCompany) {
                    return this.$ENUMS.EndCustomerRoles.CUSTOM
                } else if (this.viewAllTasksOwnCompany && this.useFilter) {
                    return this.$ENUMS.EndCustomerRoles.ANALYST
                } else if (this.viewAllTasksOwnCompany) {
                    return this.$ENUMS.EndCustomerRoles.READ_ONLY
                }
            }
        },
        partnerRoles() {
            return Object.keys(this.$ENUMS.PartnerRoles).map((key) => {
                return {id: key, name: this.$t(`users.partner_roles.${key}`)}
            })
        },
        partnerRole: {
            set(data) {
                if (this.$ENUMS.PartnerRoles.SALES_MANAGER === data) {
                    this.uncheckAllPartnerGrants()
                    this.allTaskGrantsResponsibleCompanies = true
                    this.allUserGrantsResponsibleCompanies = true
                    this.allCompanyGrantsResponsibleCompanies = true
                    this.viewAllTasksResponsibleCompanies = true

                    this.responsibleForAllCustomers = true
                } else if (this.$ENUMS.PartnerRoles.GROUP_MANAGER === data) {
                    this.uncheckAllPartnerGrants()
                    this.allTaskGrantsResponsibleCompanies = true
                    this.allUserGrantsResponsibleCompanies = true
                    this.allCompanyGrantsResponsibleCompanies = true
                    this.viewAllTasksResponsibleCompanies = true

                    this.allCompanyGrantsOnGroupCompanies = true
                } else if (this.$ENUMS.PartnerRoles.ACCOUNTER === data) {
                    this.uncheckAllPartnerGrants()
                    this.allTaskGrantsResponsibleCompanies = true
                    this.allUserGrantsResponsibleCompanies = true
                    this.allCompanyGrantsResponsibleCompanies = true
                    this.viewAllTasksResponsibleCompanies = true
                } else if (this.$ENUMS.PartnerRoles.NONE === data) {
                    this.uncheckAllPartnerGrants()
                }
            },
            get() {
                if (
                    this.allTaskGrantsResponsibleCompanies &&
                    this.allUserGrantsResponsibleCompanies &&
                    this.allCompanyGrantsResponsibleCompanies &&
                    this.viewAllTasksResponsibleCompanies
                ) {
                    if (this.responsibleForAllCustomers) {
                        return this.$ENUMS.PartnerRoles.SALES_MANAGER
                    } else if (this.allCompanyGrantsOnGroupCompanies) {
                        return this.$ENUMS.PartnerRoles.GROUP_MANAGER
                    } else {
                        return this.$ENUMS.PartnerRoles.ACCOUNTER
                    }
                } else {
                    if (
                        /*
                        !this.responsibleForAllCustomers &&
                        !this.allCompanyGrantsOnGroupCompanies &&
                        */
                        !this.allTaskGrantsResponsibleCompanies &&
                        !this.allUserGrantsResponsibleCompanies &&
                        !this.allCompanyGrantsResponsibleCompanies &&
                        !this.viewAllTasksResponsibleCompanies
                    ) {
                        return this.$ENUMS.PartnerRoles.NONE
                    } else {
                        return this.$ENUMS.PartnerRoles.CUSTOM
                    }
                }
            }
        },
        endCustomerRoles() {
            return Object.keys(this.$ENUMS.EndCustomerRoles).map((key) => {
                return {id: key, name: this.$t(`users.end_customer_roles.${key}`)}
            })
        },
        disableUserCompanySelection() {
            return !(this.currentUser.grants[this.grants.ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES] || this.isLocateRiskAdmin || this.isOrganizationalAdmin)
        },
        selectedOrganization() {
            return this.organizationsWithGrants.find((org) => org.id === this.localValue.CompanyId)
        },
        canModifyGrants() {
            return this.hasGrantsOnCompany(this.selectedOrganization?.CompanyId)
        },
        isDistributor() {
            return this.currentUserOrganization.isDistributor
        },
        isMyCompany() {
            return this.currentUserOrganization.id === this.localValue.CompanyId
        },
        getDomainOfSelectedOrganization() {
            try {
                return this.organizationsWithGrants.filter((org) => org.id == this.localValue?.CompanyId)[0].domain
            } catch (error) {
                return ''
            }
        },
        getdomainFromEmail() {
            try {
                return this.localValue.email.split('@')[1]
            } catch (error) {
                return ''
            }
        }
    },
    watch: {
        userToUpdate() {
            this.resetLocalValue()
        },
        selectedOrganization() {
            this.loadResponsibleGroups()
        },
        userGrants() {
            this.loadResponsibleGroups()
        },
        allCompanyGrantsOnGroupCompanies() {
            if (this.allCompanyGrantsOnGroupCompanies) {
                this.responsibleForAllCustomers = false
            }
        },
        responsibleForAllCustomers() {
            if (this.responsibleForAllCustomers) {
                this.allCompanyGrantsOnGroupCompanies = false
            }
        }
    },
    methods: {
        ...mapActions(['loadLimitedOrganizationsInfo', 'loadUserGrants']),

        checkUseFilterPossible() {
            if (!this.viewAllTasksOwnCompany && !this.allTaskGrantsOwnCompany) {
                this.useFilter = false
            }
        },

        onUseFilterCheck() {
            if (this.useFilter) {
                this.viewAllTasksOwnCompany = true
            }
        },

        validate() {
            this.givenNameMissing = !this.localValue.givenName
            this.surnameMissing = !this.localValue.surname
            this.emailMissing = !this.localValue.email || !this.isEmail(this.localValue.email)
            this.companyIdMissing = !this.localValue.CompanyId
            this.passwordsDoesNotMatch = !(this.localValue.password === this.newPasswordRepeat)
            return !(this.givenNameMissing || this.surnameMissing || this.emailMissing || this.companyIdMissing || this.passwordsDoesNotMatch)
        },

        hasGrantsOnCompany(companyId) {
            return (
                this.isLocateRiskAdmin ||
                this.isOrganizationalAdmin ||
                (companyId && this.userData.companyId && companyId === this.userData.companyId && this.hasAllUserGrantsOwnCompany) ||
                (companyId && this.userData.companyId && companyId !== this.userData.companyId && this.isResponsibleForAllCustomers) ||
                (companyId && this.userData.companyId && companyId !== this.userData.companyId && this.hasAllUserGrantsResponsibleCompanies)
            )
        },

        loadResponsibleGroups() {
            if (this.userGrants) {
                this.userGrants.responsibleGroups ??= []
                this.selectedGroups = this.userGrants.responsibleGroups.map((group) => this.selectedOrganization?.availableGroups.indexOf(group)) ?? []
            }
        },

        submit() {
            this.localValue.email = this.localValue.email.trim()
            if (!this.validate()) {
                return
            }

            this.localValue.givenName = this.localValue.givenName.trim()
            this.localValue.surname = this.localValue.surname.trim()
            this.localValue.email = this.localValue.email.trim()

            if (this.locateRiskAdmin) {
                this.localValue.grants = createLocateRiskAdminFrame()
            } else if (this.organizationalAdmin) {
                this.localValue.grants = createOrganizationalAdminFrame()
            } else {
                this.localValue.grants = createUserFrame()
            }
            if (this.generalPrizes) {
                this.localValue.grants.specialGrants
                    ? this.localValue.grants.specialGrants.push(this.actions.MODIFY_GENERAL_PRICES)
                    : (this.localValue.grants.specialGrants = [this.actions.MODIFY_GENERAL_PRICES])
            }
            if (this.unvalidatedTasks) {
                this.localValue.grants.specialGrants
                    ? this.localValue.grants.specialGrants.push(this.actions.CREATE_UNVALIDATED_TASKS)
                    : (this.localValue.grants.specialGrants = [this.actions.CREATE_UNVALIDATED_TASKS])
            }
            if (this.unvalidatedMGMT) {
                this.localValue.grants.specialGrants
                    ? this.localValue.grants.specialGrants.push(this.actions.CREATE_UNVALIDATED_MGMT)
                    : (this.localValue.grants.specialGrants = [this.actions.CREATE_UNVALIDATED_MGMT])
            }
            if (this.viewTrial) {
                this.localValue.grants.specialGrants
                    ? this.localValue.grants.specialGrants.push(this.actions.VIEW_TRIAL)
                    : (this.localValue.grants.specialGrants = [this.actions.VIEW_TRIAL])
            }

            if (this.riskLists) {
                this.localValue.grants.specialGrants
                    ? this.localValue.grants.specialGrants.push(this.actions.MODIFY_RISK_LISTS)
                    : (this.localValue.grants.specialGrants = [this.actions.MODIFY_RISK_LISTS])
            }

            if (this.selectedOrganization) {
                this.localValue.grants.responsibleGroups = this.selectedGroups.map((index) => this.selectedOrganization.availableGroups[index])
            }

            if (this.allTaskGrantsOwnCompany) {
                this.localValue.grants[this.grants.ALL_TASK_GRANTS_ON_OWN_COMPANY] = true
                this.localValue.grants[this.grants.CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY] = true
            }
            if (this.viewAllTasksOwnCompany) {
                this.localValue.grants[this.grants.CAN_VIEW_ALL_TASKS_FROM_OWN_COMPANY] = true
            }
            if (this.allCompanyGrantsOwnCompany) {
                this.localValue.grants[this.grants.ALL_COMPANY_GRANTS_ON_OWN_COMPANY] = true
            }
            if (this.allUserGrantsOwnCompany) {
                this.localValue.grants[this.grants.ALL_USER_GRANTS_ON_OWN_COMPANY] = true
            }
            if (this.allCompanyGrantsOnGroupCompanies) {
                this.localValue.grants[this.grants.RESPONSIBLE_FOR_GROUP_COMPANIES] = true
            }
            if (this.allTaskGrantsResponsibleCompanies) {
                this.localValue.grants[this.grants.ALL_TASK_GRANTS_ON_RESPONSIBLE_COMPANIES] = true
                this.localValue.grants[this.grants.CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES] = true
            }
            if (this.viewAllTasksResponsibleCompanies) {
                this.localValue.grants[this.grants.CAN_VIEW_ALL_TASKS_FROM_RESPONSIBLE_COMPANIES] = true
            }
            if (this.allCompanyGrantsResponsibleCompanies) {
                this.localValue.grants[this.grants.ALL_COMPANY_GRANTS_ON_RESPONSIBLE_COMPANIES] = true
            }
            if (this.allUserGrantsResponsibleCompanies) {
                this.localValue.grants[this.grants.ALL_USER_GRANTS_ON_RESPONSIBLE_COMPANIES] = true
            }

            if (this.responsibleForAllCustomers) {
                this.localValue.grants[this.grants.RESPONSIBLE_FOR_ALL_CUSTOMERS_OF_COMPANY] = true
            }

            const pushOrCreateArray = (obj, prop, elem) => {
                Array.isArray(obj[prop]) ? obj[prop].push(elem) : (obj[prop] = [elem])
            }

            const addToGrantsOwnCompany = (grant) => {
                pushOrCreateArray(this.localValue.grants, 'grantsOwnCompany', grant)
            }
            this.useFilter && addToGrantsOwnCompany(this.actions.USE_FILTER)

            const addToQuestionnaireGrants = (grant) => {
                pushOrCreateArray(this.localValue.grants, 'questionnaireGrants', grant)
            }
            this.viewQuestionnairesGrants && addToQuestionnaireGrants(this.actions.VIEW_QUESTIONNAIRE)
            this.modifyQuestionnairesGrants && addToQuestionnaireGrants(this.actions.MODIFY_QUESTIONNAIRE)
            this.inviteToQuestionnairesGrants && addToQuestionnaireGrants(this.actions.INVITE_TO_QUESTIONNAIRE)
            this.viewOwnAnsweringsGrants && addToQuestionnaireGrants(this.actions.VIEW_OWN_ANSWERING)
            this.viewSupplierAnsweringsGrants && addToQuestionnaireGrants(this.actions.VIEW_SUPPLIER_ANSWERING)
            this.modifyAnsweringsGrants && addToQuestionnaireGrants(this.actions.MODIFY_ANSWERING)

            if (this.getdomainFromEmail != this.getDomainOfSelectedOrganization) {
                this.userAddConfirmation = true
            } else {
                this.createUser(true)
            }
        },

        createUser(value) {
            if (value) {
                this.$emit('save', this.localValue)
            }
            this.userAddConfirmation = false
        },

        resetLocalValue() {
            this.localValue = this.userToUpdate
                ? JSON.parse(JSON.stringify(this.userToUpdate))
                : {
                      active: true,
                      newsletter: false,
                      releaseNotes: true,
                      givenName: '',
                      surname: '',
                      email: '',
                      CompanyId: '',
                      password: '',
                      currentPassword: ''
                  }
            // Password is not appended when userToUpdate is loaded into localValue
            this.localValue.password = ''
            this.localValue.currentPassword = ''
        },

        checkLRAdmin(event) {
            if (event) {
                this.organizationalAdmin = true
                this.unvalidatedTasks = true
                this.unvalidatedMGMT = true
                this.viewTrial = true
                this.riskLists = true
                this.generalPrizes = true
                this.allTaskGrantsOwnCompany = true
                this.allUserGrantsOwnCompany = true
                this.useFilter = true
                this.allCompanyGrantsOwnCompany = true
                this.viewAllTasksOwnCompany = true
                this.allTaskGrantsResponsibleCompanies = true
                this.allUserGrantsResponsibleCompanies = true
                this.allCompanyGrantsResponsibleCompanies = true
                this.viewAllTasksResponsibleCompanies = true
                this.responsibleForAllCustomers = true
            } else {
                this.organizationalAdmin = false
                this.unvalidatedTasks = false
                this.unvalidatedMGMT = false
                this.viewTrial = false
                this.riskLists = false
                this.generalPrizes = false
                this.allTaskGrantsOwnCompany = false
                this.allUserGrantsOwnCompany = false
                this.useFilter = false
                this.allCompanyGrantsOwnCompany = false
                this.viewAllTasksOwnCompany = false
                this.allTaskGrantsResponsibleCompanies = false
                this.allUserGrantsResponsibleCompanies = false
                this.allCompanyGrantsResponsibleCompanies = false
                this.viewAllTasksResponsibleCompanies = false
                this.responsibleForAllCustomers = false
            }
        },

        checkOAdmin(event) {
            if (event) {
                this.organizationalAdmin = true
                this.allTaskGrantsOwnCompany = true
                this.allUserGrantsOwnCompany = true
                this.useFilter = true
                this.allCompanyGrantsOwnCompany = true
                this.viewAllTasksOwnCompany = true
                this.allTaskGrantsResponsibleCompanies = true
                this.allUserGrantsResponsibleCompanies = true
                this.allCompanyGrantsResponsibleCompanies = true
                this.viewAllTasksResponsibleCompanies = true
                this.responsibleForAllCustomers = true
                // questionnaire grants
                this.viewQuestionnairesGrants = true
                this.modifyQuestionnairesGrants = true
                this.inviteToQuestionnairesGrants = true
                this.viewOwnAnsweringsGrants = true
                this.viewSupplierAnsweringsGrants = true
                this.modifyAnsweringsGrants = true
            } else {
                this.organizationalAdmin = false
                this.generalPrizes = false
                this.allTaskGrantsOwnCompany = false
                this.allUserGrantsOwnCompany = false
                this.useFilter = false
                this.allCompanyGrantsOwnCompany = false
                this.viewAllTasksOwnCompany = false
                this.allTaskGrantsResponsibleCompanies = false
                this.allUserGrantsResponsibleCompanies = false
                this.allCompanyGrantsResponsibleCompanies = false
                this.viewAllTasksResponsibleCompanies = false
                this.responsibleForAllCustomers = false
                this.allCompanyGrantsOnGroupCompanies = false
                // questionnaire grants
                this.viewQuestionnairesGrants = false
                this.modifyQuestionnairesGrants = false
                this.inviteToQuestionnairesGrants = false
                this.viewOwnAnsweringsGrants = false
                this.viewSupplierAnsweringsGrants = false
                this.modifyAnsweringsGrants = false
            }
        },

        checkTaskGrantsOwnCompany(event) {
            if (event) {
                this.viewAllTasksOwnCompany = true
                this.allTaskGrantsOwnCompany = true
            } else {
                this.viewAllTasksOwnCompany = false
                this.allTaskGrantsOwnCompany = false
            }
        },

        checkTaskGrantsResponsibleCompanies(event) {
            if (event) {
                this.viewAllTasksResponsibleCompanies = true
                this.allTaskGrantsResponsibleCompanies = true
            } else {
                this.viewAllTasksResponsibleCompanies = false
                this.allTaskGrantsResponsibleCompanies = false
            }
        },

        uncheckAllEndCustomerGrants() {
            this.organizationalAdmin = false
            this.allTaskGrantsOwnCompany = false
            this.viewAllTasksOwnCompany = false
            this.useFilter = false
            this.allCompanyGrantsOwnCompany = false
            this.allUserGrantsOwnCompany = false
        },

        uncheckAllPartnerGrants() {
            this.allTaskGrantsResponsibleCompanies = false
            this.allUserGrantsResponsibleCompanies = false
            this.allCompanyGrantsResponsibleCompanies = false
            this.viewAllTasksResponsibleCompanies = false
            this.responsibleForAllCustomers = false
            this.allCompanyGrantsOnGroupCompanies = false
        }
    }
}
</script>

<style scoped lang="scss">
.wrapper {
    display: grid;
    grid-row-gap: 20px;
}
</style>
