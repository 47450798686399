export default {
    async getInfoForVRM(productId) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/nps/check/vrm/${productId}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            return await response.json()
        }
    },
    async getInfoForCyberRiskAnalysis(scanId) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/nps/check/report/${scanId}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            return await response.json()
        }
    },
    async getInfoForManagement() {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/nps/check/managenent-scan`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            return await response.json()
        }
    },

    async saveVRMFeedback(productId, data) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/nps/check/vrm/${productId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (response.ok) {
            return await response.json()
        }
    },
    async saveCyberRiskAnalysisFeedback(scanId, data) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/nps/check/report/${scanId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (response.ok) {
            return await response.json()
        }
    },
    async saveManagementFeedback(data) {
        const response = await fetch(`${Window.$apiUrl}/api/v1/rest/products/nps/check/managenent-scan`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data)
        })
        if (response.ok) {
            return await response.json()
        }
    }
}
