const PARTNER_MATERIAL_IDS = {
    CUSTOMER_MEETING_SUPPORT: 'b82e4e6f-149b-4605-8c12-5c5b87510968',
    ONBOARDING_SALES_TEAM_GUIDANCE: '70830c39-8da5-4f26-b52a-be1d44fd276c',
    ONBOARDING_SALES_TEAM_APPOINTMENT: '746d7266-d341-4c78-b9f2-b1e9058c43e2',
    TECHNICAL_SUPPORT_EMAIL: '4f35b542-0e39-47a2-b5bd-a49c521d312a',
    TECHNICAL_SUPPORT_APPOINTMENT: 'be75b915-9fa5-4060-b5c9-184292eacf42',
    VIDEOS_AND_TUTORIALS: 'd512b7a8-fbf2-4482-acf9-6e2681e21b01',
    PRICE_LIST_SKU_CSU: '0b0c1749-598c-4331-b2b1-6062d4280b29',
    PRICE_LIST_DOWNLOAD: 'e9da829a-1837-45ac-a353-f4ce267c0cbc',
    INSTRUCTIONS_FOR_CUSTOMER_ONBOARDING: '3f028ed6-4add-4458-af8b-3e622c8c2ae9',
    INFO_FLYER: 'ce1a7de6-6bfd-4c35-9db2-841410417500',
    COBRANDING_MARKETING_KIT: '57397368-84c3-4fec-9511-1ddddc83b21e'
}
Object.freeze(PARTNER_MATERIAL_IDS)

module.exports = PARTNER_MATERIAL_IDS
