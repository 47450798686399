<template>
    <div>
        <confirm ref="confirm"></confirm>
        <v-app
            v-if="
                startRender &&
                $route.name !== 'PrintPage' &&
                $route.name !== 'PrintInventoryPage' &&
                $route.name !== 'PrintVRMPage' &&
                $route.name !== 'PrintVRMSupplierPage' &&
                $route.name !== 'PrintFilterPage' &&
                $route.name !== 'PrintQuestionnaireReport' &&
                $route.name !== 'PrintSupplierReport'
            "
        >
            <ShareDialog
                v-if="userData && showShareDialog"
                v-model="showShareDialog"
                :share-supplier="['RiskListScansOverview', 'RiskList'].includes($route.name) && shareDialog.type === 'TASK'"
                :data="shareDialog"
                @close="shareDialog = false"
            ></ShareDialog>

            <v-snackbar app top :value="showDefaultError" @input="hideDefaultError()" color="error">
                <span style="color: white">{{ $t('general.defaultError') }}</span>
                <template v-slot:action="{attrs}">
                    <v-btn v-bind="attrs" icon @click="hideDefaultError()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <v-snackbar app top :value="showDefaultSnackbar" @input="hideDefaultSnackbar()" color="primary">
                <span style="color: white">{{ defaultSnackbarText }}</span>
                <template v-slot:action="{attrs}">
                    <v-btn v-bind="attrs" icon @click="hideDefaultSnackbar()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>

            <v-app-bar v-if="!hasToActivate2FA" app elevation="0" color="primary">
                <v-app-bar-nav-icon v-if="!isGiveConsentPage && !isLoginPage && !isSharedPage && !isPasswordResetPage" @click="drawer = !drawer" dark>
                    <v-badge :value="hasAnswerings" dot color="red" overlap offset-x="6" offset-y="8">
                        <v-icon>mdi-menu</v-icon>
                    </v-badge>
                </v-app-bar-nav-icon>
                <v-img
                    v-if="!isSharedPage"
                    @click="isLoggedIn() ? $router.push({name: 'ProductOverview'}).catch(() => {}) : ''"
                    contain
                    max-width="200px"
                    :src="getOrganizationStyle()"
                    max-height="40px"
                />
                <v-img v-else-if="!!organizationStyle" contain max-width="200px" :src="getOrganizationStyle()" max-height="40px" />

                <v-spacer></v-spacer>
                <!-- TODO: remove
                <v-icon offset-y style="color: white" tooltip="Show help" @click="showPermanentHelp()">mdi-help-circle-outline</v-icon>
                -->
                <v-menu offset-y>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-bind="attrs" v-on="on" color="primary" outlined style="margin-top: 4px">
                            <div>
                                <country-flag v-if="userData" :rounded="true" :country="userData.language === 'en' ? 'gb' : userData.language"></country-flag>
                                <country-flag v-else :rounded="true" :country="language"></country-flag>
                            </div>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item dense v-for="(lang, index) in languages" :key="index" @click="changeLanguageLocal(lang)">
                            <v-list-item-title>{{ $t(`languages.${lang}`) }}</v-list-item-title>
                            <v-list-item-icon>
                                <div style="margin-top: -8px">
                                    <country-flag :country="lang === 'en' ? 'gb' : lang"></country-flag>
                                </div>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <UserMenu v-if="userData && !isLoginPage && !isSharedPage && !isPasswordResetPage"></UserMenu>
                <LRAdminDisplayToggle v-if="isLocateRiskAdmin" />
            </v-app-bar>
            <Navigation v-if="userData" :drawer="drawer" @close="(ev) => (this.drawer = ev)"></Navigation>
            <v-main @newOrderCreated="orderedEvent">
                <v-container>
                    <router-view></router-view>
                    <NpsDialog
                        v-if="!isLocateRiskAdmin && !shouldSkipNPSDisplay"
                        :new-product-ordered="newProductOrdered"
                        @resetProductOrdered="newProductOrdered = false"
                    />
                </v-container>
            </v-main>

            <v-footer :padless="padless">
                <v-card flat tile width="100%" class="lighten-1 text-center" style="background-color: var(--v-background-base) !important">
                    <v-card-text class="black--text">
                        <div style="margin-bottom: 1em">
                            <a style="color: black; text-decoration: none" href="https://locaterisk.com/impressum/" target="_blank">{{
                                $t('general.imprint')
                            }}</a>
                            <a style="color: black; text-decoration: none; margin-left: 1em" href="https://locaterisk.com/datenschutz/" target="_blank">{{
                                $t('general.privacy')
                            }}</a>
                            <a
                                v-if="userData"
                                style="color: black; text-decoration: none; margin-left: 1em"
                                :href="`${$apiUrl}/api/v1/rest/redirect/f5876da6-8bab-4337-b19b-fd6156472457`"
                                target="_blank"
                                >{{ $t('general.features_and_feedback') }}</a
                            >
                            <a
                                v-if="userData"
                                style="color: black; text-decoration: none; margin-left: 1em"
                                :href="`${$apiUrl}/api/v1/rest/redirect/8523a11d-7444-4a69-9aa4-84ed4cfc22ee`"
                                target="_blank"
                                >{{ $t('general.documentation') }}</a
                            >
                        </div>
                        {{ new Date().getFullYear() }} — <strong>LocateRisk</strong>
                    </v-card-text>
                </v-card>
            </v-footer>

            <v-tour />
            <!-- disable clicks in permanent help-->
            <v-overlay v-if="permanentHelpVisible" @click="showPermanentHelp()" :opacity="0" style="z-index: 999"></v-overlay>
            <!-- TODO place somewhere else so it does not is on top of move up button -->
            <div
                style="position: fixed; z-index: 999; bottom: 3rem; right: 3rem; background-color: white; opacity: 0.9; width: fit-content; height: fit-content"
            >
                <v-alert
                    v-if="permanentHelpVisible && contactData"
                    text
                    dense
                    color="teal"
                    icon="mdi-contacts"
                    border="left"
                    style="margin: 0 !important; font-size: 0.75rem"
                >
                    <div>{{ contactData.mailContact }}</div>
                    <div>{{ contactData.companyURL }}</div>
                    <div>{{ $t('printManagementReport.footer.phone', {contactPhone: contactData.phoneContact}) }}</div>
                </v-alert>
            </div>
            <div class="permanent-help-floating-button" @click="showPermanentHelp()" v-if="showHelpButton">
                <v-icon class="permanent-help-floating-button-icon" offset-y tooltip="Show help">mdi-help-circle-outline</v-icon>
            </div>
        </v-app>

        <v-app
            v-if="
                startRender &&
                ($route.name === 'PrintPage' ||
                    $route.name === 'PrintInventoryPage' ||
                    $route.name === 'PrintFilterPage' ||
                    $route.name === 'PrintVRMPage' ||
                    $route.name === 'PrintVRMSupplierPage' ||
                    $route.name === 'PrintQuestionnaireReport' ||
                    $route.name === 'PrintSupplierReport')
            "
            ><router-view></router-view
        ></v-app>
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'
import Languages from '../../models/models/enums/Languages'
import Navigation from '@/components/Navigation'
import ShareDialog from '@/components/dialogs/ShareDialog'
import UserMenu from '@/views/users/UserMenu'
import Confirm from '@/components/Confirm.vue'
import {throttle} from '@/helper/functions.js'
import {isLoggedIn} from './new/helper/Auth'
import LRAdminDisplayToggle from './components/LRAdminDisplayToggle.vue'
import NpsDialog from './components/dialogs/NpsDialog.vue'
import dayjs from 'dayjs'

export default {
    name: 'App',
    props: {
        companyId: {
            type: String
        }
    },
    metaInfo() {
        return {
            meta: [{name: 'description', content: this.$t('meta_info.app.description')}]
        }
    },
    components: {UserMenu, ShareDialog, Navigation, Confirm, LRAdminDisplayToggle, NpsDialog},
    data() {
        return {
            showCreateTaskDialog: false,
            showShareDialog: false,
            showHelpButton: false,
            drawer: false,
            startRender: false,
            padless: true,
            attrs: {
                absolute: true
            },
            throttledActivityRequest: undefined,
            contactData: null,
            newProductOrdered: false
        }
    },

    async created() {
        await this.restoreTokenFromCookie()
        if (
            /*this.$route.params.productId ||*/ this.$route.params.taskId ||
            this.$route.params.scanId ||
            this.$route.params.questionnaireId ||
            this.$route.params.answeringId
        ) {
            await this.loadLoginStyleFromProductOwner({
                //productId: this.$route.params.productId,
                taskId: this.$route.params.taskId,
                scanId: this.$route.params.scanId,
                questionnaireId: this.$route.params.questionnaireId,
                answeringId: this.$route.params.answeringId
            })
        }

        // report activity every Minute if user is logged in with a long session and actively using the website
        this.throttledActivityRequest = throttle(this.reportActivity, 1000 * 60)
        window.addEventListener('mousemove', this.mouseMove)
        window.addEventListener('click', this.mouseClick)
        window.addEventListener('keydown', this.keyDown)
        window.addEventListener('scroll', this.scroll)

        if (
            !isLoggedIn() &&
            !window.location.pathname.includes('login') &&
            !window.location.pathname.includes('password-reset') &&
            !window.location.pathname.includes('give-consent') &&
            !window.location.pathname.includes('shared') &&
            !window.location.pathname.startsWith('/print') &&
            !window.location.pathname.startsWith('/vendor-risk-management/supplier') &&
            !window.location.pathname.includes('unsubscribe')
        ) {
            await this.$router.replace(`/login${this.$route.query.companyId ? `?companyId=${this.$route.query.companyId}` : ''}`)
        }

        if (this.isSharedPage || window.location.pathname.includes('shared')) {
            const meta = document.createElement('meta')
            meta.name = 'robots'
            meta.content = 'noindex'

            document.getElementsByTagName('head')[0].appendChild(meta)
        }

        await this.$nextTick()
        this.startRender = true

        if (this.currentUserOrganization?.CompanyId) {
            this.contactData = await this.loadContactData(this.currentUserOrganization.CompanyId)
        }
    },
    destroyed() {
        window.removeEventListener('mousemove', this.mouseMove)
        window.removeEventListener('click', this.mouseClick)
        window.removeEventListener('keydown', this.keyDown)
        window.removeEventListener('scroll', this.scroll)
    },
    computed: {
        ...mapState({
            hasToActivate2FA: (state) => state.organization?.force2fa && !state.userData?.hasMFA,
            userData: (state) => state.userData,
            showDefaultError: (state) => state.showDefaultError,
            showDefaultSnackbar: (state) => state.showDefaultSnackbar,
            shareDialogState: (state) => state.shareDialog,
            defaultSnackbarText: (state) => state.defaultSnackbarText,
            organizationStyle: (state) => state.organizationStyle,
            language: (state) => state.language,
            permanentHelpVisible: (state) => state.tours.permanentHelpVisible,
            state: (state) => state
        }),
        ...mapGetters(['currentUserOrganization', 'pendingAnswerings', 'isLocateRiskAdmin']),

        hasAnswerings() {
            return this.pendingAnswerings?.total > 0
        },
        hasSelfAnswerings() {
            return this.pendingAnswerings?.self > 0
        },
        languages() {
            return Object.values(Languages)
        },

        shareDialog: {
            set(data) {
                this.setShowShareDialog({show: false, type: null, itemId: null})
            },
            get() {
                return this.shareDialogState
            }
        },

        isLoginPage() {
            return this.$route.name === 'Login'
        },

        isSharedPage() {
            return this.$route.name === 'Shared'
        },

        isPasswordResetPage() {
            return this.$route.name === 'PasswordReset'
        },

        loginCompanyId() {
            return this.$route.query.companyId
        },

        isGiveConsentPage() {
            return this.$route.name === 'GIVE_CONSENT'
        },

        shouldSkipNPSDisplay() {
            const storedDate = localStorage.getItem('skipFeedbackDate')
            if (storedDate) {
                return dayjs().isSame(dayjs(storedDate), 'day')
            } else {
                return false
            }
        }
    },
    methods: {
        ...mapActions([
            'login',
            'restoreTokenFromCookie',
            'logout',
            'changeLanguage',
            'loadUsers',
            'updateToken',
            'loadLoginStyle',
            'loadLoginStyleFromProductOwner',
            'setPageTitle',
            'showPermanentHelp',
            'loadContactData'
        ]),
        ...mapMutations(['setShowShareDialog', 'hideDefaultError', 'hideDefaultSnackbar', 'setLanguage']),

        isLoggedIn() {
            return isLoggedIn()
        },

        async changeLanguageLocal(language) {
            if (!this.userData) {
                this.setLanguage(language)
            }
            await this.changeLanguage(language)
        },

        async localLogin() {
            try {
                await this.login({
                    email: this.email,
                    password: this.password
                })
            } catch {
                this.showError = true
            }
        },

        getOrganizationStyle() {
            if (this.organizationStyle) {
                return `${Window.$apiUrl}/api/rest/public/organization/${this.organizationStyle.id}/logo/display.png`
            } else {
                if (!this.loginCompanyId) {
                    return `${Window.$apiUrl}/api/rest/public/organization/standard/logo/display.png`
                }
            }
        },
        mouseMove() {
            this.throttledActivityRequest()
        },
        mouseClick() {
            this.throttledActivityRequest()
        },
        keyDown() {
            this.throttledActivityRequest()
        },
        scroll() {
            this.throttledActivityRequest()
        },
        async reportActivity() {
            if (isLoggedIn()) {
                const loggedIn = await this.updateToken()
                if (!loggedIn) {
                    window.location.reload()
                }
            }
        },
        async showQuestionnairePopup() {
            const options = {
                persistent: true,
                yes: this.$t('questionnaires.hasAnswerings.button.gotoQuestionnaireOverview'),
                no: this.$t('questionnaires.hasAnswerings.button.cancel')
            }
            if (this.isLocateRiskAdmin) {
                options['key'] = 'do-not-show-has-answering-again'
            }
            if (this.hasSelfAnswerings) {
                const confirmed = await this.$root.$confirm.open(
                    this.pendingAnswerings.self == 1 ? this.$t('questionnaires.hasAnswerings.title') : this.$t('questionnaires.hasAnswerings.titleMultiple'),
                    this.pendingAnswerings.self == 1 ? this.$t('questionnaires.hasAnswerings.text') : this.$t('questionnaires.hasAnswerings.textMultiple'),
                    options
                )
                if (confirmed) {
                    if (this.pendingAnswerings.self == 1) {
                        // open only answering directly
                        this.$router.push(`/questionnaires/answer/${this.pendingAnswerings.direct}`).catch((err) => {})
                    } else {
                        this.$router.push('/questionnaires')
                    }
                }
            }
        },
        orderedEvent() {
            this.newProductOrdered = true
        }
    },
    watch: {
        async $route() {
            if (this.$route.name) {
                if (this.$route.params.taskId || this.$route.params.scanId || this.$route.params.questionnaireId || this.$route.params.answeringId) {
                    await this.loadLoginStyleFromProductOwner({
                        taskId: this.$route.params.taskId,
                        scanId: this.$route.params.scanId,
                        questionnaireId: this.$route.params.questionnaireId,
                        answeringId: this.$route.params.answeringId
                    })
                } else if (this.$route.query.companyId) {
                    await this.loadLoginStyle(this.$route.query.companyId)
                } else {
                    await this.loadLoginStyleFromProductOwner({})
                }

                const supportedRoutesForHelp = [
                    'CyberriskAnalysisOverview',
                    'ReportPage',
                    'TagFilterPage',
                    'TagFilterApplyPage',
                    'AddFilterTagsPage',
                    'EditFilterTagsPage',
                    'VendorRiskManagement',
                    'VendorRiskManagementWithTab'
                    // 'VendorRiskManagementSupplier'
                ]
                this.showHelpButton = supportedRoutesForHelp.includes(this.$route.name) ? true : false
            }

            if (this.$route.fullPath == '/') {
                this.showQuestionnairePopup()
            }
        },

        hasSelfAnswerings() {
            if (this.hasSelfAnswerings && this.$route.fullPath == '/') {
                this.showQuestionnairePopup()
            }
        }
        /*
        isLoggedIn() {
            if (!isLoggedIn() && this.$route.name !== 'Shared' && this.$route.name !== 'PrintPage' && this.$route.name !== 'PrintInventoryPage') {
                this.$router.replace(`/login${this.$route.query.companyId ? `?companyId=${this.$route.query.companyId}` : ''}`)
            }
        }
        */
    },
    mounted() {
        this.$root.$confirm = this.$refs.confirm
    }
}
</script>

<style>
@import '../node_modules/cropperjs/dist/cropper.css';
</style>

<style lang="scss">
#app {
    font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // color: #2c3e50;
    font-weight: 300;
}

@import 'styles/GlobalStyles';

.v-app-bar__nav-icon span svg path {
    color: white;
}
</style>
